@import "../../../../stylesheets/colors";

.LinkedAccounts {
  &__Item {
    display: flex !important;
    &__Content { flex: 1}
    &__InstitutionName {
      font-size: 14px;
      line-height: 1.36rem;
      letter-spacing: 0.3px;
      color: $gulf;

      span {
        margin-left: auto;
      }
    }
    &__Details {
      font-size: 11px;
      letter-spacing: 0.2px;
      color: $dusty-gray;
      width: 100%;

      &__Container{
        position: relative;
        margin: 0.5em 0px;
        padding: 0.5em 10px;
        &:not(.LinkedAccounts__Item__Details__Container-edit):hover {
          background: $soft-peach;
        }
        &:hover &__Btns {
          display: block;
        }
        &-edit {
          border: inset 2px;
          padding: 1rem;
          font-size: 11px;
        }
        &__Btns {
          position: absolute;
          top: 0.5em;
          right: 0.5em;
          display: none;
          cursor: pointer;
          @media (min-width: 320px) and (max-width: 767px) { display: block; }
        }
        &__Actions {
          margin: 0.5em 0px;
          display: flex;
          justify-content: space-between;
          @media (min-width: 767px) and (max-width: 992px) { display: block; }
        }
        i.icon {
          line-height: 20px;
          font-size: 1.15em;
          &.action {
            margin: 0 4px;
          }
        }
      }
      &__ActionUpdate {
        cursor: pointer;
        &:hover {
          color: $primary;
        }
      }
      &__Edit__Actions {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
.margin.linked-accounts {
  padding: 12px 10px 0;

  .Add_Plaid__Account {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
}

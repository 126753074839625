@import "../../../../stylesheets/colors";

.ui.segment.Profit-And-Loss__Top {
  background-color: $gulf;
  display: flex;
  flex-direction: column;
}

.ui.checkbox {
  label {
    color: white;
    font-weight: 700;
  }
}

.Profit-And-Loss {
  &__FlexBox {
    display: flex;
  }

  &__User-Info__Selected-Form,
  &__Title {
    font-size: 1rem;
  }

  &__User-Info__Selected-Form,
  &__Name {
    text-transform: uppercase;
  }

  &__User-Info {
    display: flex;
    flex-direction: column;
    color: $white;
    font-size: 1.25rem;
    font-weight: 700;
    height: auto !important;
    width: 65%;
  }

  &__User-Info__Selected-Form,
  &__Date {
    font-size: 1rem;
    color: $mercury;
  }

  &__Date-Label {
    margin-top: auto;
    font-size: 1rem;
    font-style: italic;
    & i.edit.icon {
    margin-left: 1rem;
    }
  }

  &__Date {
    display: flex;
    flex-direction: column;
    margin: 0.25rem 0;
    width: 60%;
  }

  &__Export {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;

    & > span {
      color: $glacier;
      cursor: pointer;
      display: block;
      font-size: 0.9rem;
      &:hover {
        color: $picton-blue;
      }
    }
  }

  &__Month_to_Month {
    margin-top: 1.25rem;

    & .Title {
      color: $white;
      font-size: 1.0rem;
      text-transform: uppercase;
      font-weight: 700;
    }

    & .Expand__Button__Container {
      & #tagName {
        width: 70%;
      }

      display: flex;
      justify-content: space-between !important;
    }

    & .Export__Remain__Balances {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.Professional {
  text-color: #7c7c7c;
}

button.ui.button {
  &.Professional__AddAccountant__Button {
    margin-top: 1.5em;
    margin-left: 1.5em;
    text-transform: uppercase;
    color: #1481ff;
    background: #fff none;

    &:hover {
      color: #fff;
      background: #1481ff none;
    }

    &:disabled {
      color: #c9c9c9;
    }
  }
}

.ui.segment {
  &.Professional__Card {
    padding: 0;
    padding-top: 1em;
  }
}

.Professional__AddAccountant__Text {
  text-transform: uppercase;
}

div.Professional__Card__Invitation {
  padding-top: 1em;
  padding-bottom: 1em;
}

div.Professional__Card__Instructions {
  margin-left: 1.5em;
  margin-right: 1.5em;
}

.add-accountant {
  margin-bottom: 15px;
}

@import "../../../stylesheets/colors";

.ui.segment.Transactions {
  &__Container {
    background-color: $white;
    display: flex;
    flex-direction: column;
    padding: 0;
    border: none;
    box-shadow: none;

    > table {
      color: #34495e;
      > thead tr th:not(:first-child) { font-size: 11px; }
      > tbody tr td:nth-child(5) { color: #2aa7dc; }
      > tbody tr td:nth-child(6) { color: #3a5695; }
    }
  }
}

.ui.container.Transactions__Pagination-container {
  &.header {
    margin-top: 1rem;
    margin-bottom: 0;
  }

  margin-bottom: 1rem;

  & .ui.pagination.menu {
    border: 0;
    box-shadow: none;
  }

  & .ui.menu .item {
    border: solid 1px rgb(51, 76, 138);
    margin: 0 0.25rem;
    outline: none;

    &.active {
    border-color: $primary;
    background-color: $primary;
      color: $white;
    }
  }
}

.ui.table {
  border-collapse: collapse;
}

.ui.table thead th.Transactions__Header--HeaderCell, .ui.table tbody tr {
  cursor: pointer;
}

.ui.table tr.Transactions__Row {
  border-left: 4px solid $chambray;

  &.--Income {
    border-left: 4px solid $curious-blue;
  }
}

td.Transactions__Checkbox-Cell {
  & > div {
    display: flex;
    width: 35px;
    align-items: center;
    justify-content: flex-end;
    margin-right: 0;
  }
  &.--NewTransaction > div {
    justify-content: space-between;
  }
}

td.Transaction__Category {
  color: $primary;
}

img.ui.mini.image {
  width: 10px;
}
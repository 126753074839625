.list-invitee-container * {
  margin: 5px 0;
}

.ui.header {
  &.ListInvitee__Header {
    background: #f4f4f4 none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    color: #7a7a7a;
    padding-left: 1.5em;
  }
}

.ShowInvitee__Container:not(:last-child) {
  border-bottom: 1px solid #f4f4f4;
}

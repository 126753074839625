@import "../../../stylesheets/colors";

.Card {
  margin: 20px 0px;
  border-radius: 2px;
  box-shadow: 0 3px 5px 0 #eee;
  background-color: #fff;

  .margin {
    padding: 12px 20px;
  }

  .autoHide { visibility: hidden; }
  &:hover .autoHide { visibility: visible; }

  button { border: 1px solid transparent; outline: none; cursor: pointer; }
  header {
    display: flex;
    align-items: center;
  
    h1 {
      flex: 1;
      font-size: 16px;
      font-weight: 900;
      letter-spacing: 2px;
      color: $gulf;
      margin: 0px;
      text-transform: uppercase;
    }
  }
}
@import "../../../../../stylesheets/colors";

.ProfileDetailsReadOnly {
  > div:first-of-type {
    display: flex;
    align-items: center;

    > * { padding: 0px 20px; }
  }

  h6 {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.8px;
    color: #b2bac4;
    padding: 0px 20px;
    margin: 0px;
  }

  &__FullName {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #34495e;
  }

  &__Email {
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #34495e;
  }

  &__footer {
    line-height: 1.36;
    letter-spacing: 0.3px;
    color: #34495e;
    background: #b2bac4;

    > div > div:first-of-type { font-weight: bold; }
  }
}

.ui.segment.ProfileDetailsReadOnly-ImgName {
  align-items: center;
  display: flex;
  width: 4.75rem;
  height: 4.75rem;
  font-size: 1.5rem;
  justify-content: space-evenly;
  background-color: $alabaster;
  font-weight: bold;

  & > div {
    font-size: 2rem;
    color: $chambray;
  }
}

@import "../../../stylesheets/colors";

.Invoice {
  &--dropdown {
    border-radius: 5px;
    height: 40px;
  }
  &--manage {
    margin-top: 10px !important;
  }
  &--title {
    font-size: 30px;
    & .client-name {
      font-weight: bold;
    }
  }
  &--advanced-search {
    text-decoration: underline;
    color: #d86476;
  }
  &--advanced-search:hover {
    color: #fe5a72;
    cursor: pointer;
  }
  &--tbcontrol {
    background-color: #eee;
  }
  &--grid-item {
    display: flex;
    align-items: center;
  }
  &--divider {
    width: 100%;
    transform: none;
    -webkit-transform: none;
    border-color: lightGray;
  }
  &--tab {
    background: white !important;
    border: 1px solid #e0e1e2 !important;
  }
  &--tab1 {
    background: #e0e1e2 !important;
    border: 1px solid #e0e1e2 !important;
  }
  & .Detail-table1 {
    width: 280px;
    td {
      width: 100px;
    }
  }
  
  & .opacity-pan {
    width: 100%;
    height: 100%;
    background: lightgrey;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
  }
  
  & .all-opacity-pan {
    position: absolute;
    width: 100%;
    background: white;
    height: 100%;
    opacity: 0.5;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.Invoice__Stats_Date_Selector {
  padding: 0 0 12px 0;
  display: flex;
  justify-content: center;
}

.Invoice_Stats__Content {
  vertical-align: center;
  background-color: $cadet-blue;

  &__Filters {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    &--Header {
      color: $gulf;
      text-align: start;
    }
    &--Content {
      color: $white;
      font-size: 1.1rem;
      word-break: break-word;
      line-height: 20px;
    }
    &--Select {
      width: 100%;
    }
    height: 100%;
    padding: 12px;
    font-weight: 700;
    gap: 12px;
    @media only screen and (min-width: 768px) {
      height: 100%;
    }
  }

  &__Graph {
    background-color: $white;
  }
}

.Invoice_Stats__Content__Filters {
}
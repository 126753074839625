@import "../../../stylesheets/colors";

.Report-Form {
  &__Text-Container {
    overflow: auto;
  }

  &__Sourcelist {
    list-style: none;
    padding-left: 0;
  }

  &__Sourcelist-item {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__QM > i.disabled.icon {
    color: rgb(64, 81, 104);
    opacity: 1 !important;
  }
}

.Report-Form__Segment {
  &-Container {
    align-items: center;

    &.selected span { color: $primary; font-weight: bold; }
    span { font-size: 1.33em; }
  }

  &--segment {
    width: 150px;
    height: 150px;
    padding: 0px !important;
  }

  &--image {
    width: 100%;
    height: 100%;
    opacity: 0.3;
    cursor: pointer;
    transition: opacity 1s;

    &--selected, &:hover { opacity: 1; }
  }

  &--selected {
    border: solid 2px $primary !important;
    position: relative;
    &::before {
      position: absolute;
      bottom: -1rem;
      left: calc(50% - 16px);
      content: '';
      background-image: url('../../../images/icon-white-check.svg');
      background-repeat: no-repeat;
      background-position: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #34495e;
      z-index: 1;
    }
  }

  &-text {
    margin-top: 0.5rem;
  }

  &-show-more {
    margin-top: 1rem;
    cursor: pointer;
    &:focus {
      outline: none;
      text-decoration: underline;
      color: $primary;
    }
  }
}

.Button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 12px;
  width: 120px;
  border-radius: 50px;
  color: #fff;
  background-color: #e2868f;
  font-size: 12px;
  width: 120px;
  border-radius: 50px;
  display: inline-block;
  transition: all 0.3s ease 0s;

  &:hover {
    text-decoration: none;
    color: #e2868f;
    background-color: #f8f9fa;
    border-color: #e2868f;
  }

  &--bg {
    font-size: 0.9rem;
    width: 166px;
  }

  &--outline {
    color: #8c8c8c;
    border-color: #8c8c8c;
    background-color: white;

    &:hover {
      color: white;
      background-color: #e2868f;
      border-color: #e2868f;
    }
  }
}

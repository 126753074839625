@import "../../../stylesheets/colors";

.ui.segment.Profit-Summary {
  padding: 0;
  margin-bottom: 1rem;
  height: 30rem;
  @media only screen and (min-width: 768px) {
    height: 16.25rem;
  }
}

.ui.grid.Profit-Summary__Container {
  background: $alabaster;
  display: flex;
  padding: 0;
  margin: 0 auto;
  height: 100%;

  .Profit-Summary__Content {
    padding: 0;

    &--Filters {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      background-color: $cadet-blue;
      width: 25%;
      height: 40%;
      padding: 1rem;
      @media only screen and (min-width: 768px) {
        height: 100%;
      }
    }
    &--Graphics {
      background-color: $white;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 50%;
      height: 60%;
      @media only screen and (min-width: 768px) {
        height: 100%;
      }
    }
  }
}

.Profit-Summary__Filters {
  &--Header {
    color: $gulf;
    font-weight: 700;
  }
  &--Profit {
    color: $white;
    font-size: 1.8rem;
    font-weight: 700;
    word-wrap: break-word;
    &::before {
      content: '$';
    }
  }
}

.Profit-Summary__Doughnut--Label {
  &-income {
    color: $glacier;
    span {
      text-decoration: underline;
      text-decoration-color: $glacier;
    }
  }
  &-expenses {
    color: $chambray;
    span {
      text-decoration: underline;
      text-decoration-color: $chambray;
    }
  }

  span {
    font-size: 1.5rem;
    color: $waterloo;
    text-underline-position: under;
    &::before {
      content: '$';
    }
  }
}